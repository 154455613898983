import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import ContactPersonCard from "../../molecules/ContactPersonCard"
import DatoImage from "../DatoImage"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"
import * as styles from "./DatoContactCollectionRef.module.scss"

const DatoContactCollectionRef = (props: Queries.DatoContactCollectionRefFragment) => {
  // console.debug("Rendering DatoContactCollectionRef", props)

  if (!props.contactCollection) return null

  return (
    <div className={styles.container} itemScope itemType="https://schema.org/OrganizationRole">
      <div id={slugify(props.contactCollection.title)} className={"layout-container"}>
        {props.contactCollection.title && <h2 itemProp="roleName">{props.contactCollection.title}</h2>}
        {/* {props.contactCollection.description && <p itemProp="description">{props.contactCollection.description}</p>} */}
        <DatoStructuredTextBlock data={props.contactCollection.text} shiftHeadings={2} />
        <div className={styles.cards}>
          {props.contactCollection.contacts?.map((person, i) => {
            if (!person) return null
            const name = `${person.firstname} ${person.lastname}`
            return (
              <div
                key={person.id}
                className={[styles.card, props.contactCollection?.highlightFirstContact && !i && styles.bigCard]
                  .filter(Boolean)
                  .join(" ")}>
                <ContactPersonCard
                  image={
                    person.image ? (
                      <DatoImage image={person.image.image} alt={person.image?.alt || name || ""} />
                    ) : undefined
                  }
                  name={name}
                  role={person.roleRef?.role ?? undefined}
                  phone={person.phone ?? undefined}
                  email={person.email ?? undefined}
                  details={person.details ?? undefined}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DatoContactCollectionRef

export const query = graphql`
  fragment DatoContactCollectionRef on DatoCmsContactCollectionRef {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    contactCollection {
      title
      contacts {
        id
        ...DatoContactPerson
      }
      highlightFirstContact
      text {
        value
        links {
          ... on Node {
            __typename
          }
          ... on DatoCmsRouteLink {
            ...DatoRouteLink
          }
          ... on DatoCmsExternalUrl {
            ...DatoExternalUrl
          }
          ... on DatoCmsArticleLink {
            ...DatoArticleLink
          }
        }
      }
    }
  }
`
